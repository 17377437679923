import cn from 'classnames'
import { useMemo, useState, useEffect, useRef } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { appendRefParameter } from '@lib/utils/tools'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import { motion, AnimatePresence } from 'framer-motion'
import Ankerlogo from './Ankerlogo'

import {
  CheckCircleIcon,
  XMarkIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/solid'

import { MULPASS_URL, I18N_STORE_DOMAIN } from '@shopify/const'

import Icon from '@components/icons'
import useCustomer from '@shopify/customer/use-customer'
import { Picture, LoadingDots } from '@components/ui'
import { Searchbar, UserNav } from '@components/common'
import { commerceEnabled, getThemeUrl } from '@lib/utils/tools'
import { easingTransitions } from '@lib/utils/animate'

import Button from '@components/control/Button'

import s from './Header.module.css'
import { User } from '@components/common/UserNav/UserNav'
import CountDown from './CountDown'
import NewCollections from './NewCollections'
import SceneBanner from './SceneBanner'
import NewExplore from './Explore'
import Installer from './Installer'

const UnfoldWingsMobile = ({
  lang,
  pageType,
  rootDark,
  shopCommon,
  mobileMenuOpen,
  mobileShowDetail,
  hasLiveContent,
  dropDownContent,
  dropDownSmallContent,
  currentDownBoxContent,
  currentSmallBoxContent,
  setDropDownSmallBoxContent,
  setDropDownBoxContent,
  onMobileMenuHidden,
  getCollection,
  getMainCollection,
  metafields,
  setUserPopLeft,
  userPopLeft,
  onRegistrations,
  registrationsSettings,
}) => {
  const scrollRef = useRef()
  const router = useRouter()
  const { locale } = router
  const { setRelativeLink } = useRelativeLink()
  const { data: customer } = useCustomer()
  const { navUser, navNotification } = metafields || {}

  const [collectionHover, setCollectionHover] = useState(0)

  const currentCollectionHover = useMemo(() => {
    return (
      currentDownBoxContent && currentDownBoxContent?.content?.[collectionHover]
    )
  }, [currentDownBoxContent, collectionHover])

  const CUSTOMERAUTH = useMemo(
    () => commerceEnabled('customerAuth', locale),
    [locale]
  )

  useEffect(() => {
    const contentElement = scrollRef.current

    if (contentElement && mobileMenuOpen) {
      disableBodyScroll(contentElement, {
        reserveScrollBarGap: true,
      })
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [mobileShowDetail, mobileMenuOpen])

  const [initialNotification, animateNotification, exitNotification] = [
    {
      opacity: 0,
      x: -30,
    },
    {
      opacity: 1,
      x: 0,
      transition: {
        ease: easingTransitions?.easeInOutQuart,
        duration: 0.3,
        delay: 0.2 + 0.05 * (dropDownContent?.length - 1),
      },
    },
    {
      opacity: 0,
      x: -30,
      transition: {
        ease: easingTransitions?.easeInQuart,
        duration: 0.4,
      },
    },
  ]

  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  const [curIndex, setCurIndex] = useState(-1)

  const isShowNext = useMemo(() => {
    if (
      (currentDownBoxContent &&
        ['newCollections', 'sceneBanner']?.includes(
          currentDownBoxContent?.mode
        )) ||
      (currentSmallBoxContent &&
        ['explore', 'installer']?.includes(currentSmallBoxContent?.mode))
    )
      return true
    else {
      return false
    }
  }, [currentDownBoxContent, currentSmallBoxContent])

  return (
    <AnimatePresence>
      {mobileMenuOpen && (
        <motion.div
          layout
          className={cn(s.mobileDropBox)}
          initial={{
            x: '100%',
          }}
          animate={{
            x: 0,
            transition: {
              ease: easingTransitions?.easeOutQuart,
              duration: 0.3,
            },
          }}
          exit={{
            x: '100%',
            transition: {
              ease: easingTransitions?.easeInQuart,
              duration: 0.3,
            },
          }}
        >
          <motion.div
            className={cn('layer', s.mobileDropTop)}
            initial="initial"
            animate={() =>
              (mobileShowDetail || !lang?.navTopDesc) && !isShowNext
                ? 'screen2'
                : 'screen1'
            }
            variants={{
              initial: {
                height:
                  pageType === 'powerStation' || pageType === 'hes'
                    ? 'auto'
                    : 120,
              },
              screen1: {
                height:
                  pageType === 'powerStation' || pageType === 'hes'
                    ? 'auto'
                    : 120,
              },
              screen2: { height: 50 },
            }}
          >
            {/* 手机端 展开后 Box - 顶部背景白板 */}
            <motion.div
              layout
              className={s.bgBack}
              initial={{ translateY: '-100%' }}
              exit={{ translateY: '-100%' }}
              animate={{ translateY: '0%' }}
              transition={{
                duration: 0.3,
                delay: 0.1,
                ease: easingTransitions?.easeInOutQuart,
              }}
            ></motion.div>

            <div className={cn(s.content, 'content relative z-20')}>
              <AnimatePresence mode="popLayout">
                <motion.div
                  transition={{
                    duration: 0.3,
                    ease: easingTransitions?.easeInOutQuart,
                  }}
                  initial={{
                    opacity: 1,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  {/* 手机端 展开后 Box - 顶部第一段 */}
                  <div className={s.navTop}>
                    <div className={s.left}>
                      {(!mobileShowDetail || isShowNext) && (
                        <motion.button
                          className={s.logoButton}
                          transition={{
                            duration: 0.3,
                            ease: easingTransitions?.easeInOutQuart,
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          onClick={() => {
                            router.push({
                              pathname:
                                pageType === 'charger'
                                  ? `/`
                                  : setRelativeLink({
                                      link: lang?.navTopToHref,
                                    }),
                              query: {
                                ref:
                                  pageType === 'charger'
                                    ? 'naviMenu'
                                    : 'naviMenu_pps',
                              },
                            })
                            onMobileMenuHidden()
                          }}
                        >
                          <Ankerlogo
                            pageType={pageType}
                            rootDark={rootDark}
                            locale={locale}
                            s={s}
                          />
                        </motion.button>
                      )}

                      {mobileShowDetail && !isShowNext && (
                        <motion.button
                          className={s.logoButton}
                          transition={{
                            duration: 0.3,
                            ease: easingTransitions?.easeInOutQuart,
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          onClick={() => {
                            setDropDownSmallBoxContent('')
                            setCollectionHover(-1)
                            setDropDownBoxContent('')
                            router.replace(
                              {
                                pathname: router.pathname,
                                query: router.query,
                              },
                              undefined,
                              { shallow: true }
                            )
                          }}
                        >
                          <Icon
                            iconKey="arrowPrev"
                            width="24"
                            height="24"
                            className={s.icon}
                          />
                        </motion.button>
                      )}
                    </div>

                    {mobileShowDetail && !isShowNext && (
                      <motion.div
                        className={s.mobileContentTitle}
                        transition={{
                          duration: 0.3,
                          ease: easingTransitions?.easeInOutQuart,
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        {currentDownBoxContent?.text ||
                          currentSmallBoxContent?.text}
                      </motion.div>
                    )}

                    <div className={cn(s.actionBox, s.right)}>
                      {navUser && (
                        <UserNav
                          className={s.userNav}
                          data={navUser}
                          onClick={() => {
                            if (userPopLeft) {
                              setUserPopLeft(false)
                            } else {
                              setUserPopLeft('unset')
                            }
                          }}
                        />
                      )}
                      <button
                        className={s.menuIcon}
                        onClick={() => {
                          router.replace(
                            {
                              pathname: router.pathname,
                              query: router.query,
                            },
                            undefined,
                            { shallow: true }
                          )
                          onMobileMenuHidden()
                          router.replace(
                            {
                              pathname: router.pathname,
                              query: router.query,
                            },
                            undefined,
                            { shallow: true }
                          )
                        }}
                        aria-label="menu"
                      >
                        <Icon
                          iconKey="close"
                          width="24"
                          height="24"
                          className={s.icon}
                        />
                      </button>
                    </div>
                  </div>

                  {/* 手机端 展开后 Box - 顶部第二段 */}
                  {(!mobileShowDetail || isShowNext) && lang?.navTopDesc && (
                    <motion.div
                      className={cn(s.categoryMobileBox, {
                        [s.categoryMobileBoxCharger]: pageType === 'charger',
                        [s.categoryMobileBoxPowerStation]:
                          pageType === 'powerStation' || pageType === 'hes',
                      })}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          ease: easingTransitions?.easeInOutQuart,
                          duration: 0.3,
                          delay: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        transition: {
                          ease: easingTransitions?.easeInQuart,
                          duration: 0.3,
                        },
                      }}
                    >
                      {pageType === 'charger' && (
                        <button
                          key="mobileCharger"
                          className={s.categoryButton}
                          onClick={() => {
                            router.push({
                              pathname: setRelativeLink({
                                link: lang?.navTopToHref,
                              }),
                              query: {
                                ref: 'naviMenu',
                              },
                            })

                            onMobileMenuHidden()
                          }}
                        >
                          <div className={s.secondaryNavLogoBox}>
                            <div className={s.secondaryNavLogoMobile}>
                              <Icon
                                iconKey="anker"
                                fill="#00a7e1"
                                className={cn(
                                  'mr-1 w-[60px]',
                                  s.noRootDarkLogo
                                )}
                              />
                              <Icon
                                iconKey="power"
                                animate="light"
                                className="w-[52px]"
                              />
                            </div>
                            <p
                              className={s.brandDescribeMobile}
                              dangerouslySetInnerHTML={{
                                __html: lang?.navTopDesc,
                              }}
                            />
                          </div>
                          <div className={s.secondaryNavProductImgMobile}>
                            {lang?.navTopImg && (
                              <Image
                                loading="lazy"
                                alt=""
                                src={lang?.navTopImg}
                                fill
                              />
                            )}
                          </div>
                          <div className={s.secondaryNavCheck}>
                            <span>{lang?.navTopToPps}</span>
                          </div>
                        </button>
                      )}
                      {(pageType === 'powerStation' || pageType === 'hes') && (
                        <a
                          className="flex"
                          href={`${setRelativeLink({
                            link: lang?.navTopToHref,
                          })}?ref=naviBar_pps`}
                        >
                          <div
                            className={s.categoryButtonPowerStation}
                            dangerouslySetInnerHTML={{
                              __html: lang?.navTopDesc,
                            }}
                          />
                          {lang?.navTopDescCountDown && (
                            <CountDown
                              daysSill={lang?.navTopDescCountDown?.daysSill}
                              className="ml-[5px] font-medium text-[#FF742D]"
                              onlyTime={true}
                              startTime={
                                new Date(lang?.navTopDescCountDown?.startTime)
                              }
                              endTime={
                                new Date(lang?.navTopDescCountDown?.endTime)
                              }
                              settings={lang?.navTopDescCountDown}
                            />
                          )}
                        </a>
                      )}
                    </motion.div>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </motion.div>

          <div className={cn('layer', s.mobileContent)} ref={scrollRef}>
            <AnimatePresence mode="popLayout">
              {/* 手机端 展开后 Box - 内容区域 第一屏 */}
              {(!mobileShowDetail || isShowNext) && (
                <div className={cn(s.content, 'content')}>
                  <motion.ul className={s.menuMobile}>
                    {dropDownContent &&
                      dropDownContent?.map((item, i) => {
                        const [initial, animate, exit] = [
                          {
                            opacity: 0,
                            x: -30,
                          },
                          {
                            opacity: 1,
                            x: 0,
                            transition: {
                              ease: easingTransitions?.easeInOutQuart,
                              duration: 0.3,
                              delay: 0.2 + 0.05 * i,
                            },
                          },
                          {
                            opacity: 0,
                            x: -30,
                            transition: {
                              ease: easingTransitions?.easeInQuart,
                              duration: 0.3,
                            },
                          },
                        ]
                        return item?.mode === 'linkto' ? (
                          <motion.li
                            key={`menuMobile-${item?.text}-${i}`}
                            initial={initial}
                            animate={animate}
                            exit={exit}
                          >
                            <a
                              style={{ background: '#f7f8fa' }}
                              href={`${setRelativeLink({ link: item?.url })}${
                                pageType === 'charger'
                                  ? '?ref=naviMenu'
                                  : '?ref=naviMenu_pps'
                              }`}
                              onClick={() => {
                                onMobileMenuHidden()
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                            </a>
                          </motion.li>
                        ) : ['newCollections', 'sceneBanner']?.includes(
                            item?.mode
                          ) ? (
                          <motion.li
                            key={`menuMobile-${item?.text}-${i}`}
                            initial={initial}
                            animate={animate}
                            exit={exit}
                          >
                            <button
                              className={cn(
                                `${
                                  curIndex === i
                                    ? '!text-[var(--brand-color)]'
                                    : ''
                                }`
                              )}
                              onClick={() => {
                                setCollectionHover(-1)
                                setDropDownBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                                if (i === curIndex) {
                                  setCurIndex(-1)
                                } else {
                                  setCurIndex(i)
                                }
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon
                                className={cn(
                                  'transition-all',
                                  `${
                                    curIndex === i
                                      ? 'rotate-[90deg]'
                                      : 'rotate-[0deg]'
                                  }`
                                )}
                                width={18}
                              />
                            </button>
                            <motion.div
                              className={s.optionContent}
                              animate={curIndex === i ? 'show' : 'hidden'}
                              variants={{
                                show: {
                                  height: 'auto',
                                  transition: {
                                    duration: 0.3,
                                    ease: easingTransitions?.easeInOutQuart,
                                  },
                                },
                                hidden: {
                                  height: 0,
                                },
                              }}
                            >
                              {currentDownBoxContent?.mode ===
                                'newCollections' && (
                                <NewCollections
                                  s={s}
                                  currentDownBoxContent={currentDownBoxContent}
                                  collectionHover={collectionHover}
                                  currentCollectionHover={
                                    currentCollectionHover
                                  }
                                  setCollectionHover={setCollectionHover}
                                  shopCommon={shopCommon}
                                  lang={lang}
                                ></NewCollections>
                              )}
                              {currentDownBoxContent?.mode ===
                                'sceneBanner' && (
                                <SceneBanner
                                  s={s}
                                  currentDownBoxContent={currentDownBoxContent}
                                  collectionHover={collectionHover}
                                  currentCollectionHover={
                                    currentCollectionHover
                                  }
                                  setCollectionHover={setCollectionHover}
                                  shopCommon={shopCommon}
                                ></SceneBanner>
                              )}
                            </motion.div>
                          </motion.li>
                        ) : (
                          <motion.li
                            key={`menuMobile-${item?.text}-${i}`}
                            initial={initial}
                            animate={animate}
                            exit={exit}
                          >
                            <button
                              onClick={() => {
                                setCollectionHover(-1)
                                setDropDownBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                              }}
                            >
                              <div className="relative">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.text,
                                  }}
                                />
                                {item?.hotIcon && (
                                  <div className={s.hotWrap}>
                                    <Picture
                                      className="h-full w-full object-cover"
                                      source={`${item?.hotIcon} 769`}
                                    />
                                  </div>
                                )}
                              </div>
                              <ChevronRightIcon width={18} />
                            </button>
                          </motion.li>
                        )
                      })}

                    {dropDownSmallContent &&
                      dropDownSmallContent?.map((item, i) => {
                        return ['explore', 'installer']?.includes(
                          item?.mode
                        ) ? (
                          <motion.li
                            key={`menuMobile-s-${item?.text}-${i}`}
                            initial={{
                              opacity: 0,
                              x: -30,
                            }}
                            animate={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                ease: easingTransitions?.easeInOutQuart,
                                duration: 0.3,
                                delay:
                                  0.2 +
                                  0.05 * (i + dropDownContent?.length - 1),
                              },
                            }}
                            exit={{
                              opacity: 0,
                              x: -30,
                              transition: {
                                ease: easingTransitions?.easeInQuart,
                                duration: 0.3,
                              },
                            }}
                          >
                            <button
                              onClick={() => {
                                setDropDownSmallBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                                if (i + 'smallContent' === curIndex) {
                                  setCurIndex(-1)
                                } else {
                                  setCurIndex(i + 'smallContent')
                                }
                              }}
                              className={cn(
                                `${
                                  curIndex === i + 'smallContent'
                                    ? '!text-[var(--brand-color)]'
                                    : ''
                                }`
                              )}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon
                                className={cn(
                                  'transition-all',
                                  `${
                                    curIndex === i + 'smallContent'
                                      ? 'rotate-[90deg]'
                                      : 'rotate-[0deg]'
                                  }`
                                )}
                                width={18}
                              />
                            </button>
                            <motion.div
                              className={s.optionContent}
                              animate={
                                curIndex === i + 'smallContent'
                                  ? 'show'
                                  : 'hidden'
                              }
                              variants={{
                                show: {
                                  height: 'auto',
                                  transition: {
                                    duration: 0.3,
                                    ease: easingTransitions?.easeInOutQuart,
                                  },
                                },
                                hidden: {
                                  height: 0,
                                },
                              }}
                            >
                              {currentSmallBoxContent?.mode === 'explore' && (
                                <NewExplore
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  handleLiveChat={handleLiveChat}
                                  pageType={pageType}
                                  s={s}
                                />
                              )}
                              {currentSmallBoxContent?.mode === 'installer' && (
                                <Installer
                                  currentSmallBoxContent={
                                    currentSmallBoxContent
                                  }
                                  pageType={pageType}
                                  s={s}
                                />
                              )}
                            </motion.div>
                          </motion.li>
                        ) : (
                          <motion.li
                            key={`menuMobile-s-${item?.text}-${i}`}
                            initial={{
                              opacity: 0,
                              x: -30,
                            }}
                            animate={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                ease: easingTransitions?.easeInOutQuart,
                                duration: 0.3,
                                delay:
                                  0.2 +
                                  0.05 * (i + dropDownContent?.length - 1),
                              },
                            }}
                            exit={{
                              opacity: 0,
                              x: -30,
                              transition: {
                                ease: easingTransitions?.easeInQuart,
                                duration: 0.3,
                              },
                            }}
                          >
                            <button
                              onClick={() => {
                                setDropDownSmallBoxContent(item?.text)
                                window.location.hash = `navi_2_${item?.text
                                  ?.toLowerCase()
                                  .replace(/\s+/g, '_')}`
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item?.text,
                                }}
                              />
                              <ChevronRightIcon width={18} />
                            </button>
                          </motion.li>
                        )
                      })}
                  </motion.ul>
                  {(pageType === 'powerStation' || pageType === 'hes') && (
                    <motion.div
                      className={s.toHome}
                      initial={{
                        opacity: 0,
                        x: -30,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          ease: easingTransitions?.easeInOutQuart,
                          duration: 0.3,
                          delay:
                            0.2 +
                            0.05 *
                              (dropDownContent?.length +
                                dropDownSmallContent?.length -
                                2),
                        },
                      }}
                      exit={{
                        opacity: 0,
                        x: -30,
                        transition: {
                          ease: easingTransitions?.easeInQuart,
                          duration: 0.3,
                        },
                      }}
                    >
                      <a href={`/${locale}?ref=naviBar_pps`}>
                        {lang?.navTopAtag} &gt;
                      </a>
                    </motion.div>
                  )}

                  {navNotification && (
                    <>
                      <motion.hr
                        initial={initialNotification}
                        animate={animateNotification}
                        exit={exitNotification}
                        className="mt-[10px]"
                      />

                      {navNotification?.title && (
                        <motion.p
                          className={s.notificationTitle}
                          initial={initialNotification}
                          animate={animateNotification}
                          exit={exitNotification}
                        >
                          {navNotification?.title}
                        </motion.p>
                      )}

                      <motion.ul className={s.notificationCol}>
                        {navNotification?.list?.map((item, index) => {
                          const [initial, animate, exit] = [
                            {
                              opacity: 0,
                              x: -30,
                            },
                            {
                              opacity: 1,
                              x: 0,
                              transition: {
                                ease: easingTransitions?.easeInOutQuart,
                                duration: 0.3,
                                delay:
                                  0.2 +
                                  0.05 * (index + dropDownContent.length - 1),
                              },
                            },
                            {
                              opacity: 0,
                              x: -30,
                              transition: {
                                ease: easingTransitions?.easeInQuart,
                                duration: 0.4,
                              },
                            },
                          ]
                          return (
                            <motion.li
                              initial={initial}
                              animate={animate}
                              exit={exit}
                              key={index}
                            >
                              <a
                                className={s.notificationBox}
                                href={`${setRelativeLink({
                                  link: item?.href,
                                })}?ref=naviNotification`}
                              >
                                <Picture
                                  className={cn('h-[80px] w-[80px] flex-none')}
                                  source={`${item?.mobImg} 768`}
                                />
                                <div className="flex flex-col gap-[6px]">
                                  {item.title && (
                                    <p
                                      className={s.notificationBoxTitle}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    />
                                  )}

                                  {item.desc && (
                                    <p
                                      className={s.notificationBoxDesc}
                                      dangerouslySetInnerHTML={{
                                        __html: item.desc,
                                      }}
                                    />
                                  )}
                                </div>
                              </a>
                            </motion.li>
                          )
                        })}
                      </motion.ul>
                    </>
                  )}
                </div>
              )}

              {/* 手机端 展开后 Box - 内容区域 第二屏 */}
              {mobileShowDetail && !isShowNext && (
                <div className={cn(s.mobileMenuDetail, 'content')}>
                  {(currentDownBoxContent || currentSmallBoxContent) && (
                    <motion.div
                      key={`${currentDownBoxContent?.mode}-${currentDownBoxContent?.text}`}
                      className={cn(s.content)}
                      initial={{
                        opacity: 0,
                        x: -30,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          ease: easingTransitions?.easeInOutQuart,
                          duration: 0.3,
                          delay: 0.2,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        x: -30,
                        transition: {
                          ease: easingTransitions?.easeInQuart,
                          duration: 0.3,
                        },
                      }}
                    >
                      {currentDownBoxContent?.mode === 'collections' && (
                        <ul className={s.menuMobile}>
                          {currentDownBoxContent?.content?.map(
                            (collection, index) => {
                              return (
                                <li
                                  key={`collectionsMLi-${collection?.name}-${index}`}
                                  className={cn(s.option, {
                                    [s.active]: collectionHover === index,
                                  })}
                                >
                                  <button
                                    onClick={() => {
                                      if (
                                        collectionHover >= 0 &&
                                        collectionHover === index
                                      ) {
                                        setCollectionHover(-1)
                                      } else {
                                        setCollectionHover(index)
                                      }
                                      window.location.hash = `navi_3_${collection?.name
                                        ?.toLowerCase()
                                        .replace(/\s+/g, '_')}`
                                    }}
                                  >
                                    <span>{collection?.name}</span>
                                    <ChevronDownIcon
                                      width={18}
                                      className={s.collectionMenuDown}
                                    />
                                  </button>

                                  <motion.div
                                    className={s.optionContent}
                                    animate={
                                      collectionHover === index
                                        ? 'show'
                                        : 'hidden'
                                    }
                                    variants={{
                                      show: {
                                        height: 'auto',
                                        transition: {
                                          duration: 0.3,
                                          ease: easingTransitions?.easeInOutQuart,
                                        },
                                      },
                                      hidden: {
                                        height: 0,
                                      },
                                    }}
                                  >
                                    {collection?.starCard && (
                                      <div className={s.navStarCardList}>
                                        {collection?.productList?.map(
                                          (item, index) => {
                                            return (
                                              <motion.a
                                                href={`${setRelativeLink({
                                                  link: item?.url,
                                                })}?ref=${
                                                  pageType === 'charger'
                                                    ? 'naviMenu'
                                                    : 'naviMenu_pps'
                                                }`}
                                                className={s.navStarCardBox}
                                                key={`starCard-${collectionHover}-${item?.name}-${index}`}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                              >
                                                <Picture
                                                  className="h-full w-full object-cover"
                                                  source={`${item?.img}, ${item?.img1024} 1024, ${item?.img768} 768`}
                                                />
                                                <div
                                                  className={cn(
                                                    s.navStarCardContent,
                                                    {
                                                      [s.drak]:
                                                        item?.theme === 'drak',
                                                    }
                                                  )}
                                                >
                                                  <p
                                                    className={
                                                      s.navStarCardTitle
                                                    }
                                                  >
                                                    {item?.title}
                                                  </p>
                                                  <p
                                                    className={s.navStarCardSub}
                                                  >
                                                    {item?.subtitle}
                                                  </p>
                                                  <p
                                                    className={
                                                      s.navStarCardLearn
                                                    }
                                                  >
                                                    {item?.learnmore}
                                                  </p>
                                                </div>
                                              </motion.a>
                                            )
                                          }
                                        )}
                                      </div>
                                    )}
                                    {collection?.starProducts && (
                                      <div
                                        className={cn(s.navStarList, {
                                          [s.navStarListMore]:
                                            collection?.productList?.length > 1,
                                        })}
                                      >
                                        {collection?.productList?.map(
                                          (item, index) => {
                                            const url = appendRefParameter({
                                              url: item?.url,
                                              refValue:
                                                pageType === 'charger'
                                                  ? 'naviMenu'
                                                  : 'naviMenu_pps',
                                            })
                                            return (
                                              <a
                                                href={setRelativeLink({
                                                  link: url,
                                                })}
                                                className={s.productBox}
                                                key={`starProducts-${item?.name}-${index}`}
                                              >
                                                <div className={s.detail}>
                                                  <p
                                                    className={s.tag}
                                                    dangerouslySetInnerHTML={{
                                                      __html: item?.tag,
                                                    }}
                                                  />
                                                  <p
                                                    className={s.name}
                                                    dangerouslySetInnerHTML={{
                                                      __html: item?.name,
                                                    }}
                                                  />
                                                  <p
                                                    className={s.description}
                                                    dangerouslySetInnerHTML={{
                                                      __html: item?.description,
                                                    }}
                                                  />
                                                  <p
                                                    className={s.learnMore}
                                                    dangerouslySetInnerHTML={{
                                                      __html: item?.learnMore,
                                                    }}
                                                  />
                                                </div>
                                                <div className={s.productImg}>
                                                  <Picture
                                                    source={item?.img}
                                                    alt=""
                                                  />
                                                </div>
                                                <ul className={s.highlights}>
                                                  {item?.highlights?.length
                                                    ? item?.highlights
                                                        ?.split('||')
                                                        ?.map((txt, i) => {
                                                          return (
                                                            <li
                                                              key={`${txt}-${i}`}
                                                              className={
                                                                s.hightLight
                                                              }
                                                            >
                                                              <CheckCircleIcon
                                                                className="mr-2 flex-initial"
                                                                width="20"
                                                                stroke="#ff9128"
                                                              />
                                                              <span>{txt}</span>
                                                            </li>
                                                          )
                                                        })
                                                    : ''}
                                                </ul>
                                              </a>
                                            )
                                          }
                                        )}
                                        <div className={s.otherLinksBox}>
                                          {collection?.otherLinks?.map(
                                            (item, index) => {
                                              return (
                                                <a
                                                  href={setRelativeLink({
                                                    link: item?.to,
                                                  })}
                                                  className={s.HOtherLinks}
                                                  key={`HOtherLinks-${collectionHover}-${index}`}
                                                  dangerouslySetInnerHTML={{
                                                    __html: item?.label,
                                                  }}
                                                />
                                              )
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {!collection?.starProducts &&
                                      !collection?.starCard && (
                                        <>
                                          <ul className={s.productsList}>
                                            <AnimatePresence mode="popLayout">
                                              {collection?.productList?.map(
                                                (item, g) => {
                                                  const tag = item.tags?.find(
                                                    (tag) =>
                                                      tag in
                                                      (shopCommon.tagToDescription ||
                                                        {})
                                                  )
                                                  return g < 6 ? (
                                                    <li
                                                      className={s.product}
                                                      key={`productListM-${collection?.name}-${item?.name}-${g}`}
                                                    >
                                                      <a
                                                        rel="noopener noreferrer"
                                                        href={`${
                                                          locale === 'us'
                                                            ? ''
                                                            : `/${locale}`
                                                        }/products${
                                                          item?.path
                                                        }?ref=${
                                                          pageType === 'charger'
                                                            ? 'naviMenu'
                                                            : 'naviMenu_pps'
                                                        }`}
                                                        className={s.producta}
                                                      >
                                                        <div
                                                          className={s.imgBox}
                                                        >
                                                          <Image
                                                            src={
                                                              item?.images?.[0]
                                                                ?.url
                                                            }
                                                            height={100}
                                                            width={100}
                                                            alt={item?.name}
                                                            sizes="140px"
                                                          />
                                                        </div>
                                                        <p
                                                          className={s.name}
                                                          dangerouslySetInnerHTML={{
                                                            __html: item?.name,
                                                          }}
                                                        />
                                                        {tag && (
                                                          <p className={s.tag}>
                                                            {shopCommon
                                                              .tagToDescription?.[
                                                              tag
                                                            ]?.label ||
                                                              shopCommon
                                                                .tagToDescription?.[
                                                                tag
                                                              ] ||
                                                              ''}
                                                          </p>
                                                        )}
                                                      </a>
                                                    </li>
                                                  ) : (
                                                    ''
                                                  )
                                                }
                                              )}
                                            </AnimatePresence>
                                          </ul>
                                          {collection?.jumpCollection !==
                                            'hidden' && (
                                            <div className={s.bottomBar}>
                                              <ul className={s.list}>
                                                <AnimatePresence mode="popLayout">
                                                  {collection?.collectionList?.map(
                                                    (item, g) => {
                                                      return item?.name ? (
                                                        <li
                                                          key={`collectionList-${collection?.name}-${item}-${g}`}
                                                        >
                                                          <a
                                                            href={`${
                                                              locale === 'us'
                                                                ? ''
                                                                : `/${locale}`
                                                            }${
                                                              item?.path
                                                            }?ref=${
                                                              pageType ===
                                                              'charger'
                                                                ? 'naviMenu'
                                                                : 'naviMenu_pps'
                                                            }`}
                                                            rel="noopener noreferrer"
                                                            className={s.linkTo}
                                                          >
                                                            {item?.name}{' '}
                                                            &gt;&gt;
                                                          </a>
                                                        </li>
                                                      ) : (
                                                        ''
                                                      )
                                                    }
                                                  )}
                                                </AnimatePresence>
                                              </ul>
                                              <Button
                                                animateKey={`collectionHoverBtn-${collection?.name}`}
                                                className="flex-initial"
                                                border
                                                theme="brand"
                                                onClick={() => {
                                                  if (collection?.shopAllLink) {
                                                    window.location.assign(
                                                      collection?.shopAllLink
                                                    )
                                                  } else {
                                                    window.location.assign(
                                                      `${
                                                        locale === 'us'
                                                          ? ''
                                                          : `/${locale}`
                                                      }${collection?.path}${
                                                        pageType === 'charger'
                                                          ? '?ref=naviMenu'
                                                          : '?ref=naviMenu_pps'
                                                      }`
                                                    )
                                                  }
                                                  onMobileMenuHidden()
                                                }}
                                              >
                                                {collection?.jumpCollection
                                                  ? collection.jumpCollection
                                                  : lang?.seeAll?.replace(
                                                      '%collection%',
                                                      collection?.name
                                                    )}
                                              </Button>
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </motion.div>
                                </li>
                              )
                            }
                          )}
                        </ul>
                      )}

                      {currentDownBoxContent?.mode === 'navBanner' && (
                        <>
                          {currentDownBoxContent?.title && (
                            <a
                              className={s.solixTitle}
                              href={`${setRelativeLink({
                                linnk: currentDownBoxContent?.to,
                              })}?ref=${
                                pageType === 'charger'
                                  ? 'naviMenu'
                                  : 'naviMenu_pps'
                              }`}
                            >
                              <span>{currentDownBoxContent?.title}</span>
                              <ChevronRightIcon className={s.solixSvg} />
                            </a>
                          )}

                          {currentDownBoxContent?.content?.map(
                            (item, index) => {
                              return (
                                <a
                                  key={`navBanner-${item?.title}-${index}`}
                                  href={`${setRelativeLink({
                                    link: item?.href,
                                  })}?ref=${
                                    pageType === 'charger'
                                      ? 'naviMenu'
                                      : 'naviMenu_pps'
                                  }`}
                                  rel="noopener noreferrer"
                                  className={cn(s.navBanner, {
                                    [s.firstBanner]: index === 0,
                                  })}
                                >
                                  <Picture
                                    source={
                                      item?.mobileBgImg
                                        ? item?.mobileBgImg
                                        : item?.bgImg
                                    }
                                    alt=""
                                  />
                                  <div
                                    className={cn(s.navBannerTxt, {
                                      [s.modeDark]:
                                        item?.mode?.toLowerCase() === 'dark',
                                    })}
                                  >
                                    <span
                                      className={s.navBannerTitle}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.title,
                                      }}
                                    />
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item?.subtitle,
                                      }}
                                    />
                                    {pageType === 'charger' ? (
                                      <p
                                        className={s.viewMore}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.learnmore,
                                        }}
                                      />
                                    ) : (
                                      <SolixButton className="mt-[12px]">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: item?.learnmore,
                                          }}
                                        />
                                      </SolixButton>
                                    )}
                                  </div>
                                </a>
                              )
                            }
                          )}
                        </>
                      )}

                      {currentDownBoxContent?.mode === 'gather' && (
                        <>
                          {currentDownBoxContent?.content?.map(
                            (item, index) => {
                              if (item?.mode == 'row') {
                                return (
                                  <div
                                    key={index}
                                    className={cn(
                                      'flex flex-col pt-[40px] text-[#000]',
                                      { ['pt-[15px]']: index == 0 }
                                    )}
                                  >
                                    {item?.title && (
                                      <p className="mb-[20px] text-[16px] font-semibold">
                                        {item?.title}
                                      </p>
                                    )}

                                    <div className="flex flex-col gap-[15px]">
                                      {item?.list?.map((child, childIndex) => (
                                        <a
                                          key={childIndex}
                                          href={`${setRelativeLink({
                                            link: child?.href,
                                          })}?ref=${
                                            pageType === 'charger'
                                              ? 'naviMenu'
                                              : 'naviMenu_pps'
                                          }`}
                                          className={s.gatherBox}
                                        >
                                          <p>{child?.title}</p>
                                          {child?.hotIcon && (
                                            <p className="flex h-[19px] items-center justify-center rounded-[20px] bg-[#F8EFED] px-[8px] text-[13px] text-[#FF5C00]">
                                              {child?.hotIcon}
                                            </p>
                                          )}
                                        </a>
                                      ))}
                                    </div>
                                  </div>
                                )
                              }

                              if (item?.mode == 'image') {
                                return (
                                  <div
                                    key={index}
                                    className="flex flex-col pt-[30px] text-[#000]"
                                  >
                                    <div className="flex flex-col">
                                      {item?.list?.map((child, childIndex) => (
                                        <a
                                          href={`${setRelativeLink({
                                            link: child?.href,
                                          })}?ref=${
                                            pageType === 'charger'
                                              ? 'naviMenu'
                                              : 'naviMenu_pps'
                                          }`}
                                          key={childIndex}
                                          className={cn(s.gatherBox, {
                                            [s.gatherImgBox]: child?.img,
                                          })}
                                        >
                                          <div className={s.imageWrap}>
                                            <Picture
                                              className={cn(
                                                s.gatherImage,
                                                'aspect-h-[170] aspect-w-[342]'
                                              )}
                                              source={`${child?.img}, ${
                                                child?.mobImg || child?.img
                                              } 769}`}
                                              alt={child?.alt || ''}
                                            />
                                          </div>

                                          <div className="px-[30px] py-[20px]">
                                            <p className="mb-[16px]">
                                              {child?.title}
                                            </p>
                                            <p className="text-[#00A9E1]">
                                              {child?.learnMore}
                                            </p>
                                          </div>
                                        </a>
                                      ))}
                                    </div>
                                  </div>
                                )
                              }
                            }
                          )}
                        </>
                      )}

                      {/* {currentDownBoxContent?.mode === 'newCollections' && (
                        <NewCollections
                          s={s}
                          currentDownBoxContent={currentDownBoxContent}
                          collectionHover={collectionHover}
                          currentCollectionHover={currentCollectionHover}
                          setCollectionHover={setCollectionHover}
                          shopCommon={shopCommon}
                        ></NewCollections>
                      )}
                      {currentDownBoxContent?.mode === 'sceneBanner' && (
                        <SceneBanner
                          s={s}
                          currentDownBoxContent={currentDownBoxContent}
                          collectionHover={collectionHover}
                          currentCollectionHover={currentCollectionHover}
                          setCollectionHover={setCollectionHover}
                          shopCommon={shopCommon}
                        ></SceneBanner>
                      )} */}

                      {currentSmallBoxContent?.imgTitle && (
                        <p
                          key={`dropDownLeftContent-${currentSmallBoxContent?.imgTitle}`}
                          className={s.linkToTitle}
                        >
                          {currentSmallBoxContent?.imgTitle}
                        </p>
                      )}
                      {currentSmallBoxContent?.imgList?.map((item, g) => {
                        return (
                          <a
                            className={s.linkToBtn}
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=${
                              pageType === 'charger'
                                ? 'naviMenu'
                                : 'naviMenu_pps'
                            }`}
                            key={`dropDownSmallContentimg${item?.img}-${g}`}
                          >
                            <Picture source={item?.img} alt="" />
                          </a>
                        )
                      })}
                      {currentSmallBoxContent?.textTitle && (
                        <p
                          key={`dropDownRightContent-${currentSmallBoxContent?.textTitle}`}
                          className={s.linkToTitle}
                        >
                          {currentSmallBoxContent?.textTitle}
                        </p>
                      )}
                      {currentSmallBoxContent?.textList?.map((item, g) => {
                        return (
                          <a
                            href={`${setRelativeLink({
                              link: item?.url,
                            })}?ref=${
                              pageType === 'charger'
                                ? 'naviMenu'
                                : 'naviMenu_pps'
                            }`}
                            onClick={(e) => {
                              item?.url === 'online-message' &&
                                handleLiveChat(e)
                            }}
                            key={`dropDownSmallContenta-${item?.url}-${g}`}
                            className={s.linkToBtn}
                          >
                            {item?.text}
                          </a>
                        )
                      })}
                      {/* {currentSmallBoxContent?.mode === 'explore' && (
                        <NewExplore
                          currentSmallBoxContent={currentSmallBoxContent}
                          handleLiveChat={handleLiveChat}
                          s={s}
                        />
                      )}
                      {currentSmallBoxContent?.mode === 'installer' && (
                        <Installer
                          currentSmallBoxContent={currentSmallBoxContent}
                          pageType={pageType}
                          s={s}
                        />
                      )} */}
                    </motion.div>
                  )}
                </div>
              )}
            </AnimatePresence>
          </div>

          {/* 手机端 展开后 Box - 底部固定条 */}
          {metafields.navUser ? (
            <User
              data={metafields.navUser}
              userPopLeft={userPopLeft}
              preRender={metafields.preRender}
              onRegistrations={onRegistrations}
              registrationsSettings={registrationsSettings}
            />
          ) : (
            <AnimatePresence mode="popLayout">
              {!mobileShowDetail && (
                <motion.div
                  className={cn('layer', s.mobileDropBottom)}
                  initial={{
                    y: 60,
                  }}
                  animate={{
                    y: 0,
                    transition: {
                      ease: easingTransitions?.easeOutQuart,
                      duration: 0.3,
                      delay: 0.2,
                    },
                  }}
                  exit={{
                    y: 60,
                    transition: {
                      ease: easingTransitions?.easeInQuart,
                      duration: 0.3,
                    },
                    transitionEnd: {
                      display: 'none',
                    },
                  }}
                >
                  <div className={cn(s.content, 'content')}>
                    <div className={s.mobileUserBox}>
                      {CUSTOMERAUTH && (
                        <UserNav
                          className={s.userNav}
                          onRegistrations={onRegistrations}
                          registrationsSettings={registrationsSettings}
                        />
                      )}
                      <div className={s.userAction}>
                        {registrationsSettings ? (
                          <>
                            {customer ? (
                              <a
                                aria-label="account"
                                href={`${MULPASS_URL}/account/?app=${I18N_STORE_DOMAIN[
                                  locale
                                ]
                                  .split('.')
                                  .shift()}&ref=${
                                  pageType === 'charger'
                                    ? 'naviMenu'
                                    : 'naviMenu_pps'
                                }`}
                              >
                                {lang?.signUp || 'Sign up'}
                              </a>
                            ) : (
                              <a
                                aria-label="account"
                                onClick={(e) => onRegistrations(e, 'signIn')}
                              >
                                {lang?.signIn || 'Sign in'}
                              </a>
                            )}
                          </>
                        ) : (
                          <a
                            aria-label="account"
                            href={`${MULPASS_URL}/account/?app=${I18N_STORE_DOMAIN[
                              locale
                            ]
                              .split('.')
                              .shift()}&ref=${
                              pageType === 'charger'
                                ? 'naviMenu'
                                : 'naviMenu_pps'
                            }`}
                          >
                            {customer ? 'Sign up' : 'Sign in'}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default UnfoldWingsMobile
