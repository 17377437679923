import cn from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { AnimatePresence, motion, useScroll } from 'framer-motion'
import throttle from 'lodash.throttle'
import { Bars3Icon } from '@heroicons/react/24/outline'
import Icon from '@components/icons'
import { useCart } from 'lib/shopify/api/cart'
import { useUI } from '@components/ui/context'
import { UserNav } from '@components/common'
import { commerceEnabled } from '@lib/utils/tools'
import { easingTransitions } from '@lib/utils/animate'

import UnfoldWingsPC from './UnfoldWingsPC'
import UnfoldWingsMobile from './UnfoldWingsMobile'

import s from './Header.module.css'
import { display } from '@mui/system'
import { Picture } from '@components/ui'
import MobileSearchBoard, {
  PcSearchInput,
} from '@components/common/Searchbar/SearchBoard'
import { User } from '@components/common/UserNav/UserNav'
import Notification from '@components/common/Notification'
import LocaleSwitcher from '@components/common/LocaleSwitcher'
import { onSearchClick } from './trace'
import { $ } from '@components/helper'
import CountDown from './CountDown'
import Cookies from 'js-cookie'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import Ankerlogo from './Ankerlogo'

const Header = ({
  page,
  mode = {},
  lang,
  shopCommon,
  categories,
  shop_collections,
  dropDownContent,
  dropDownSmallContent,
  liveContent,
  pageType,
  navSetting,
  metafields,
}) => {
  const router = useRouter()
  const { locale } = router
  const { data } = useCart()
  const [showSearchBoard, setShowSearchBoard] = useState(false)
  const [showMobileSearchBoard, setShowMobileSearchBoard] = useState(false)
  const { setRelativeLink } = useRelativeLink()
  const {
    navUser,
    preRender,
    shopPreRender,
    navNotification,
    registrationsSettings,
  } = metafields || {}
  const {
    toggleSidebar,
    setSidebarView,
    menuTheme,
    setMenuTheme,
    setCountryModal,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
  } = useUI()

  const [collectionHover, setCollectionHover] = useState(0)

  const [scrollHiddenTransparent, setScrollHiddenTransparent] = useState(false)
  const [downShow, setShow] = useState(true)
  const [fixedReady, setReady] = useState(false)

  const [dropDownBoxOpen, setDropDownBoxOpen] = useState(false)

  const [dropDownBoxContent, setDropDownBoxContent] = useState('')

  const [dropDownSmallBoxContent, setDropDownSmallBoxContent] = useState('')

  const [dropDownPositionDefault, setDropDownPosition] = useState(0)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [userPopLeft, setUserPopLeft] = useState(false)

  const [notificationPopLeft, setNotificationPopLeft] = useState(false)

  const [isNotification, setIsNotification] = useState(false)

  const [rootDark, rootTransparent] = useMemo(() => {
    if (scrollHiddenTransparent) return [false, false]
    if (dropDownBoxOpen) return [false, false]
    if (menuTheme === 'dark' && navSetting?.navBg === 'transparent')
      return [true, true]
    if (navSetting?.navBg === 'transparent') return [false, true]
    return [false, false]
  }, [scrollHiddenTransparent, dropDownBoxOpen, menuTheme, navSetting?.navBg])
  const CUSTOMERAUTH = useMemo(
    () => commerceEnabled('customerAuth', locale),
    [locale]
  )
  const SEARCH = useMemo(() => commerceEnabled('search', locale), [locale])
  const CART = useMemo(() => commerceEnabled('cart', locale), [locale])

  const { scrollY } = useScroll()

  const navMode = useMemo(() => {
    if (navSetting?.navMode === 'none-fixed') return 'none-fixed'
    if (navSetting?.navMode === 'fixed') return 'fixed'
    return 'normal'
  }, [navSetting?.navMode])

  useEffect(() => {
    // transparent nav滚动展示逻辑专用函数
    if (navSetting?.navBg === 'transparent') {
      const removeTransparent = throttle((scrollTop) => {
        if (scrollTop > 200 && !scrollHiddenTransparent) {
          setScrollHiddenTransparent(true)
        } else if (scrollTop < 100 && scrollHiddenTransparent) {
          setScrollHiddenTransparent(false)
        }
      })

      const scrollAnimate = () => {
        let scrollTop = 0
        if (document?.documentElement && document?.documentElement?.scrollTop) {
          scrollTop = document?.documentElement.scrollTop
        } else if (document?.body) {
          scrollTop = document?.body.scrollTop
        }
        removeTransparent(scrollTop)
      }

      window.addEventListener('scroll', scrollAnimate)

      return () => window.removeEventListener('scroll', scrollAnimate)
    }
  })

  useEffect(() => {
    // nav滚动展示逻辑专用函数
    if (navMode === 'normal') {
      let lastValue = 0

      const setFixed = throttle((scrollTop) => {
        if (scrollTop > 1 && !fixedReady) {
          setReady(true)
        } else if (scrollTop <= 1 && fixedReady) {
          setReady(false)
        }
      })

      const showDownNav = throttle((scrollTop) => {
        if (scrollTop <= lastValue && fixedReady) {
          setShow(true)
        } else if (scrollTop > 100 && fixedReady) {
          setShow(false)
        } else {
          setShow(true)
        }
        lastValue = scrollTop
      }, 200)

      const scrollAnimate = () => {
        let scrollTop = 0
        if (document?.documentElement && document?.documentElement?.scrollTop) {
          scrollTop = document?.documentElement.scrollTop
        } else if (document?.body) {
          scrollTop = document?.body.scrollTop
        }
        setFixed(scrollTop)
        showDownNav(scrollTop)
      }

      window.addEventListener('scroll', scrollAnimate)

      return () => window.removeEventListener('scroll', scrollAnimate)
    }
  }, [fixedReady, navMode])

  useEffect(() => {
    let isNotification = Cookies.get('isNotification')
    if (!isNotification) {
      setIsNotification(true)
    }
  }, [])

  const countItem = (count, item) => count + item.quantity
  const itemsCount = data?.lineItems?.reduce(countItem, 0) ?? 0

  const getCollection = (handle) => {
    return categories.find((item) => item?.slug === handle)
  }

  const getMainCollection = (handle) => {
    return shop_collections?.[handle]
  }

  const hasLiveContent = useMemo(() => {
    if (liveContent) {
      let nowDate = new Date().getTime()
      let start = new Date(liveContent?.start).getTime()
      let end = new Date(liveContent.end).getTime()
      if (start <= nowDate && end >= nowDate) {
        return { ...liveContent, hasLive: true }
      }
    }
    return liveContent
  }, [liveContent])

  const LiveComponent = () => {
    const { hasLive = false, url, target } = hasLiveContent || {}
    return (
      <div className={cn(s.livePartWrap)}>
        {hasLiveContent ? (
          <a
            href={setRelativeLink({ link: url })}
            target={target || '_self'}
            className="flex h-full items-center justify-center p-[10px]"
          >
            <span className={cn('relative block', { ['hidden']: hasLive })}>
              <Icon iconKey="live" />
            </span>

            <span className={cn('relative hidden', { ['!block']: hasLive })}>
              <Icon iconKey="liveBg" />
              <i className={cn(s.linePart, s.line_1)}></i>
              <i className={cn(s.linePart, s.line_2)}></i>
              <i className={cn(s.linePart, s.line_3)}></i>
            </span>
          </a>
        ) : null}

        <Icon
          iconKey="navMenuCountries"
          className={cn(
            'mr-[4px] hidden h-[20px] w-[20px] cursor-pointer outline-none min-l:h-[18px] min-l:w-[18px]',
            s.icon
          )}
          onClick={() =>
            setCountryModal({
              visible: true,
              position: 'navimenu',
            })
          }
        />
      </div>
    )
  }

  const getProductList = ({ collectionProducts, productList }) => {
    if (productList?.[0]?.custom_title) return productList // 代表自定义产品完全都是自己填，不需要匹配
    if (!productList || productList?.length == 0) return collectionProducts
    if (!collectionProducts || collectionProducts?.length == 0) return []

    const finalProducts = []
    productList?.map((i) => {
      collectionProducts?.forEach((j) => {
        if (j?.handle === i?.handle) {
          finalProducts.push({
            ...i,
            ...j,
            variants: j?.variants?.filter((k) => i?.sku === k?.sku),
          })
        }
      })
    })

    return finalProducts
  }

  const handleNewCollections = ({ current }) => {
    const { content: cloneContent } = current
    const content =
      cloneContent?.length > 0
        ? cloneContent?.map((item) => {
            const collection = getCollection(item?.handle)
            if (item?.has_custom) {
              return {
                ...(item || {}),
                name: collection?.name || item?.custom_collection_name,
                path: collection?.path,
                productList: getProductList({
                  collectionProducts: collection?.products || [],
                  productList: item?.customProductList,
                  has_custom: item?.has_custom,
                }),
                collectionList:
                  item?.childCollectionList?.map((j) => {
                    const cur = getCollection(j?.handle)
                    return {
                      ...(j || {}),
                      name: cur?.name,
                      path: cur?.path,
                      productList: getProductList({
                        collectionProducts: cur?.products || [],
                        productList: j?.customProductList || [],
                        has_custom: item?.has_custom,
                      }),
                    }
                  }) || [],
              }
            } else {
              return {
                ...(item || {}),
                name: collection?.name,
                path: collection?.path,
                productList: collection?.products || [],
                collectionList:
                  item?.collectionList?.map((j) => {
                    const cur = getCollection(j?.handle)
                    return {
                      ...(j || {}),
                      name: cur?.name,
                      path: cur?.path,
                      productList: cur?.products || [],
                    }
                  }) || [],
              }
            }
          })
        : []

    return content
  }

  const currentDownBoxContent = useMemo(() => {
    const current =
      dropDownContent &&
      dropDownContent?.find((item) => item?.text === dropDownBoxContent)

    if (current?.['content-banner']?.length) {
      current.content = current?.['content-banner']
    } else if (current?.['content-gather']?.length) {
      current.content = current?.['content-gather']
    } else if (current?.['scene-banner']?.length) {
      current.content = current?.['scene-banner']
    } else if (current?.content?.length) {
      current.content =
        current?.mode === 'newCollections'
          ? handleNewCollections({ current })
          : current?.content?.map((item) => {
              if (item?.handle) {
                const minCollection = getMainCollection(item?.handle)
                const collection = getCollection(item?.handle)
                let collectionList =
                  minCollection?.map((handle) => {
                    const current = getCollection(handle)
                    return { name: current?.name, path: current?.path }
                  }) || []

                if (item?.otherLinks) {
                  collectionList = collectionList?.concat(
                    item?.otherLinks?.map((link) => ({
                      name: link?.label,
                      path: link?.to,
                    }))
                  )
                }
                return {
                  ...(item || {}),
                  handle: item?.handle,
                  name: collection?.name,
                  path: collection?.path,
                  productList: collection?.products,
                  collectionList,
                }
              }
              return item
            })
    }
    return current
  }, [dropDownBoxContent, dropDownContent, categories])

  const currentDownBoxContentAll = useMemo(() => {
    return dropDownContent?.map((current) => {
      if (current?.['content-banner']?.length) {
        current.content = current?.['content-banner']
      } else if (current?.['scene-banner']?.length) {
        current.content = current?.['scene-banner']
      } else if (current?.['content-gather']?.length) {
        current.content = current?.['content-gather']
      } else if (current?.content?.length) {
        current.content =
          current?.mode === 'newCollections'
            ? handleNewCollections({ current })
            : current?.content?.map((item) => {
                if (item?.handle) {
                  const minCollection = getMainCollection(item?.handle)
                  const collection = getCollection(item?.handle)
                  let collectionList =
                    minCollection?.map((handle) => {
                      const current = getCollection(handle)
                      return { name: current?.name, path: current?.path }
                    }) || []

                  if (item?.otherLinks) {
                    collectionList = collectionList?.concat(
                      item?.otherLinks?.map((link) => ({
                        name: link?.label,
                        path: link?.to,
                      }))
                    )
                  }
                  return {
                    ...(item || {}),
                    handle: item?.handle,
                    name: collection?.name,
                    path: collection?.path,
                    productList: collection?.products,
                    collectionList,
                  }
                }
                return item
              })
      }
      return current
    })
  }, [dropDownBoxContent, dropDownContent, categories])

  const currentSmallBoxContent = useMemo(() => {
    return (
      dropDownSmallBoxContent &&
      dropDownSmallContent?.find(
        (item) => item?.text === dropDownSmallBoxContent
      )
    )
  }, [dropDownSmallBoxContent, dropDownSmallContent])

  const dropDownPosition = useMemo(() => {
    if (!dropDownPositionDefault) return 0
    if (dropDownPositionDefault <= 0) return 0
    return currentSmallBoxContent?.imgTitle
      ? dropDownPositionDefault - 250
      : dropDownPositionDefault - 26
  }, [dropDownPositionDefault, currentSmallBoxContent])

  const mobileShowDetail = useMemo(
    () => dropDownBoxContent || dropDownSmallBoxContent,
    [dropDownBoxContent, dropDownSmallBoxContent]
  )

  const onMouseLeaveHidden = () => {
    setDropDownBoxContent('')
    setDropDownSmallBoxContent('')
    setDropDownBoxOpen(false)
    setUserPopLeft(false)
    setNotificationPopLeft(false)
  }

  const onMobileMenuHidden = () => {
    setMobileMenuOpen(false)
    setDropDownSmallBoxContent('')
    setShowMobileSearchBoard(false)
    setCollectionHover(-1)
    setDropDownBoxContent('')
  }

  const handleRegistrations = useCallback(
    (e, type) => {
      onMobileMenuHidden()
      if (!registrationsSettings) return
      e.preventDefault()
      setRegistrationsPopup(true)
      setRegistrationsModelStatus(type === 'signIn' ? 'quickLogin' : 'main')
    },
    [setRegistrationsPopup, setRegistrationsModelStatus, registrationsSettings]
  )

  return (
    <>
      {/* 顶部固定的 nav */}
      <motion.div
        id="header"
        key="header-nav-root"
        className={cn(s.root, {
          [s.noneFixed]: navMode === 'none-fixed',
          [s.fixed]: navMode === 'fixed',
          [s.rootTransparentMargin]: navSetting?.navBg === 'transparent',
          [s.rootDark]: rootDark,
          [s.fixedReady]: fixedReady,
          [s.downShow]: !fixedReady && downShow,
          [s.downHidden]: fixedReady && !downShow,
        })}
        onMouseLeave={onMouseLeaveHidden}
      >
        {/* 手机端 顶部固定 nav */}
        <div className="only-in-pad relative">
          <div className="layer relative z-10">
            <div className={cn(s.content, 'content')}>
              <div className={s.navTop}>
                <div className={s.left}>
                  <button
                    aria-label="go home"
                    className={s.logoButton}
                    onClick={() => {
                      window.location.assign(
                        setRelativeLink({
                          link: `${locale === 'us' ? '' : `/${locale}`}${
                            pageType === 'charger' ? '/' : '/anker-solix'
                          }${
                            pageType === 'charger'
                              ? '?ref=naviMenu'
                              : '?ref=naviMenu_pps'
                          }`,
                        })
                      )
                    }}
                  >
                    <Ankerlogo
                      pageType={pageType}
                      rootDark={rootDark}
                      locale={locale}
                      s={s}
                    />
                  </button>
                </div>

                <div className={cn(s.actionBox, s.right)}>
                  {!shopPreRender?.hidenLiveComponent && <LiveComponent />}
                  {SEARCH && (
                    <button
                      id="search"
                      aria-label="search"
                      tag="Auto-10000110"
                      alt="search"
                      onClick={() => {
                        setShowMobileSearchBoard(true)
                        setDropDownBoxContent('')
                        setDropDownSmallBoxContent('')
                        onSearchClick()
                      }}
                      className={s.search}
                    >
                      <Icon iconKey="search" className={s.icon}></Icon>
                    </button>
                  )}

                  {CART && (
                    <button
                      tag="Auto-10000112"
                      className={s.cart}
                      onClick={() => {
                        setSidebarView('CART_VIEW')
                        toggleSidebar()
                      }}
                      aria-label={`Cart items: ${itemsCount}`}
                    >
                      <Icon iconKey="cart" className={s.icon}></Icon>
                      {itemsCount > 0 && (
                        <span className={s.bagCount}>{itemsCount}</span>
                      )}
                    </button>
                  )}
                  <button
                    className={s.menuIcon}
                    onClick={() => {
                      setMobileMenuOpen(true)
                    }}
                    aria-label="menu"
                  >
                    <Icon
                      iconKey="menu"
                      width="24"
                      height="24"
                      className={s.icon}
                    ></Icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <motion.div
            className={s.navMainBoxBg}
            initial={{
              opacity: rootTransparent ? 0 : 1,
            }}
            animate={() => (rootTransparent ? 'transparent' : 'normal')}
            variants={{
              transparent: {
                opacity: 0,
                transition: {
                  delay: 0.2,
                  duration: 0.3,
                  ease: easingTransitions?.easeInOutQuart,
                },
              },
              normal: {
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: easingTransitions?.easeInOutQuart,
                },
              },
            }}
          ></motion.div>
        </div>

        <div className="not-in-pad">
          {/* PC端 顶部 nav */}
          {lang?.navTopDesc && !lang?.hideNavTopDesc && (
            <div className="layer relative z-50 border-b border-[#F2F2F2] bg-[#F7F8FA]">
              <div className={cn(s.content, 'content')}>
                {pageType === 'charger' && (
                  <div className={cn(s.secondaryNavCharger)}>
                    <a
                      href={setRelativeLink({
                        link: `${
                          locale === 'us' ? '' : `/${locale}`
                        }/anker-solix?ref=naviBar`,
                      })}
                      className={s.secondaryNavLogo}
                    >
                      <Icon
                        iconKey="anker"
                        fill="#00a7e1"
                        className={cn('mr-1 w-[52px]', s.noRootDarkLogo)}
                      />
                      <Icon
                        iconKey="power"
                        animate="light"
                        className={cn('w-[48px]', s.noRootDarkLogo)}
                      />
                    </a>
                    <a
                      href={setRelativeLink({
                        link: `${
                          locale === 'us' ? '' : `/${locale}`
                        }/anker-solix?ref=naviBar`,
                      })}
                      className={s.brandDescribe}
                      dangerouslySetInnerHTML={{
                        __html: ` | ${lang?.navTopDesc}`,
                      }}
                    />
                    <a
                      href={`${setRelativeLink({
                        link: lang?.navTopToHref,
                      })}?ref=naviBar`}
                      className={s.secondaryNavProduct}
                    >
                      {lang?.navTopImg && (
                        <div className={s.secondaryNavProductImg}>
                          <Image
                            loading="lazy"
                            alt=""
                            src={lang?.navTopImg}
                            fill
                            layout="fill"
                          />
                        </div>
                      )}
                      <span>{lang?.navTopToPps}</span>
                    </a>
                  </div>
                )}
                {(pageType === 'powerStation' || pageType === 'hes') && (
                  <div className={s.secondaryNavPps}>
                    <a
                      href={`/${locale}?ref=naviBar_pps`}
                      className={s.secondaryNavLogo}
                    >
                      <span>{lang?.navTopAtag}</span>
                    </a>
                    <a
                      href={`${setRelativeLink({
                        link: lang?.navTopToHref,
                      })}?ref=naviBar_pps`}
                      className="flex flex-grow items-center justify-center"
                    >
                      <p
                        className={cn(
                          s.slogan,
                          `${
                            lang?.navTopDescCountDown
                              ? '!flex-grow-0 !pr-[12px]'
                              : ''
                          }`
                        )}
                        dangerouslySetInnerHTML={{
                          __html: `${lang?.navTopTitle} | ${lang?.navTopDesc}`,
                        }}
                      />
                      {lang?.navTopDescCountDown && (
                        <CountDown
                          daysSill={lang?.navTopDescCountDown?.daysSill}
                          className="font-medium text-[#FF742D]"
                          onlyTime={true}
                          startTime={
                            new Date(lang?.navTopDescCountDown?.startTime)
                          }
                          endTime={new Date(lang?.navTopDescCountDown?.endTime)}
                          settings={lang?.navTopDescCountDown}
                        />
                      )}
                    </a>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={cn('layer', s.navMainBox)}>
            <div className={cn(s.content, 'content relative z-10')}>
              <div className={s.navMain}>
                <div className={cn(s.left, s.navMenuTo)}>
                  <button
                    className={s.logoButton}
                    onClick={() => {
                      window.location.assign(
                        setRelativeLink({
                          link: `${locale === 'us' ? '' : `/${locale}`}${
                            pageType === 'charger' ? '/' : '/anker-solix'
                          }${
                            pageType === 'charger'
                              ? '?ref=naviMenu'
                              : '?ref=naviMenu_pps'
                          }`,
                        })
                      )
                    }}
                  >
                    <Ankerlogo
                      pageType={pageType}
                      rootDark={rootDark}
                      locale={locale}
                      s={s}
                    />
                  </button>

                  <div className={s.navMenuTitle}>
                    {dropDownContent &&
                      dropDownContent?.map((item, i) => {
                        return item?.mode === 'linkto' ? (
                          <a
                            className={cn(s.link, 'relative block', {
                              [s.hover]: item?.text === dropDownBoxContent,
                            })}
                            key={`dropDownContent-${item?.text}-${i}`}
                            onMouseEnter={() => {
                              setDropDownBoxOpen(false)
                              setDropDownPosition(0)
                              setDropDownBoxContent(item?.text)
                              setDropDownSmallBoxContent('')
                              onMobileMenuHidden()
                            }}
                            href={`${setRelativeLink({ link: item?.url })}${
                              pageType === 'charger'
                                ? '?ref=naviMenu'
                                : '?ref=naviMenu_pps'
                            }`}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.text,
                              }}
                            />
                            {item?.hotIcon && (
                              <div className={s.hotWrap}>
                                <Picture
                                  className="h-full w-full object-cover"
                                  source={`${item?.hotIcon} 769`}
                                />
                              </div>
                            )}
                          </a>
                        ) : (
                          <motion.button
                            className={cn(s.button, 'relative', {
                              [s.hover]: item?.text === dropDownBoxContent,
                              ['hover:underline']: item?.menuHref,
                            })}
                            key={`dropDownContent-${item?.text}-${i}`}
                            onClick={() => {
                              if (item?.menuHref) {
                                window.location.href =
                                  setRelativeLink({
                                    link: item?.menuHref,
                                  }) +
                                  `${
                                    pageType === 'charger'
                                      ? '?ref=naviMenu'
                                      : '?ref=naviMenu_pps'
                                  }`
                              }
                            }}
                            onMouseEnter={() => {
                              setDropDownPosition(0)
                              setDropDownBoxContent(item?.text)
                              setDropDownSmallBoxContent('')
                              setTimeout(() => setDropDownBoxOpen(true))
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item?.text,
                              }}
                            />
                            <Icon
                              className={cn({ ['!ml-[12px]']: item?.hotIcon })}
                              iconKey="arrowDown"
                              width="12"
                              height="12"
                            />

                            {item?.hotIcon && (
                              <div className={cn(s.hotWrap, '!right-[8px]')}>
                                <Picture
                                  className="h-full w-full object-cover"
                                  source={`${item?.hotIcon} 769`}
                                />
                              </div>
                            )}
                          </motion.button>
                        )
                      })}
                  </div>
                </div>

                <div className={cn(s.right, s.navLinkTo)}>
                  {dropDownSmallContent &&
                    dropDownSmallContent?.map((item, i) => {
                      return (
                        <motion.button
                          className={cn(s.button, {
                            [s.hover]: item?.text === dropDownSmallBoxContent,
                          })}
                          key={`dropDownSmallContent-${item?.text}-${i}`}
                          onMouseEnter={(e) => {
                            const layerX =
                              e.target?.getBoundingClientRect()?.left || 0
                            setDropDownBoxContent('')
                            setDropDownSmallBoxContent(item?.text)
                            setDropDownPosition(layerX)
                            setUserPopLeft(false)
                            setNotificationPopLeft(false)
                            setTimeout(() => setDropDownBoxOpen(true))
                          }}
                        >
                          <span>{item?.text}</span>
                          <Icon
                            iconKey="arrowDown"
                            fill="#000"
                            width="12"
                            height="12"
                          />
                        </motion.button>
                      )
                    })}
                  {navNotification && (
                    <button
                      id="notification"
                      aria-label="notification"
                      alt="notification"
                      onMouseEnter={() => {
                        setUserPopLeft(false)
                        setDropDownBoxContent('')
                        setDropDownSmallBoxContent('')
                        let left
                        const l =
                          $(`#notification`)?.getBoundingClientRect()?.left
                        const cardWidth = 480
                        if (l > window.innerWidth - cardWidth) {
                          left = window.innerWidth - cardWidth
                        } else {
                          left = l - cardWidth / 2
                        }
                        Cookies.set('isNotification', true)
                        setIsNotification(false)
                        setNotificationPopLeft(left)
                      }}
                      className={cn(s.search, {
                        [s.isNotification]: isNotification,
                      })}
                    >
                      <Icon iconKey="notification" className={s.icon}></Icon>
                    </button>
                  )}
                  {SEARCH && (
                    <button
                      id="search"
                      aria-label="search"
                      tag="Auto-10000110"
                      alt="search"
                      onClick={() => {
                        setNotificationPopLeft(false)
                        setDropDownBoxContent('')
                        setDropDownSmallBoxContent('')
                        setShowSearchBoard(true)
                        onSearchClick()
                      }}
                      className={cn(s.search, {
                        [s.searchSpace]: navNotification,
                      })}
                    >
                      <Icon iconKey="search" className={s.icon}></Icon>
                    </button>
                  )}
                  {CUSTOMERAUTH && (
                    <UserNav
                      onRegistrations={handleRegistrations}
                      registrationsSettings={registrationsSettings}
                      className={s.userNav}
                      onMouseEnter={() => {
                        setNotificationPopLeft(false)
                        setDropDownBoxContent('')
                        setDropDownSmallBoxContent('')
                        let left
                        const l = $(`#userIcon`)?.getBoundingClientRect()?.left
                        const cardWidth = 380
                        if (l > window.innerWidth - cardWidth) {
                          left = window.innerWidth - cardWidth
                        } else {
                          left = l - cardWidth / 2
                        }

                        setUserPopLeft(left)
                      }}
                      data={navUser}
                    />
                  )}
                  {CART && (
                    <button
                      tag="Auto-10000112"
                      className={s.cart}
                      onClick={() => {
                        setSidebarView('CART_VIEW')
                        toggleSidebar()
                      }}
                      aria-label={`Cart items: ${itemsCount}`}
                    >
                      <Icon iconKey="cart" className={s.icon}></Icon>
                      {itemsCount > 0 && (
                        <span className={s.bagCount}>{itemsCount}</span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <motion.div
              className={s.navMainBoxBg}
              initial={{
                opacity: rootTransparent ? 0 : 1,
              }}
              animate={() => (rootTransparent ? 'transparent' : 'normal')}
              variants={{
                transparent: {
                  opacity: 0,
                  transition: {
                    delay: 0.2,
                    duration: 0.3,
                    ease: easingTransitions?.easeInOutQuart,
                  },
                },
                normal: {
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                    ease: easingTransitions?.easeInOutQuart,
                  },
                },
              }}
            ></motion.div>
            {!shopPreRender?.hidenLiveComponent && <LiveComponent />}
          </div>

          {/* PC端 展开后 Box */}
          <UnfoldWingsPC
            lang={lang}
            shopCommon={shopCommon}
            pageType={pageType}
            categories={categories}
            dropDownPosition={dropDownPosition}
            currentDownBoxContent={currentDownBoxContent}
            currentSmallBoxContent={currentSmallBoxContent}
            onMouseLeaveHidden={onMouseLeaveHidden}
            setAnimate={() => {
              if (!dropDownBoxOpen && dropDownSmallBoxContent)
                return 'initSmall'
              if (dropDownBoxOpen && dropDownSmallBoxContent) return 'openSmall'
              if ((dropDownBoxOpen && dropDownBoxContent) || showSearchBoard)
                return 'open'
              return 'closed'
            }}
            showSearchBoard={showSearchBoard}
            setShowSearchBoard={setShowSearchBoard}
            navSearch={metafields.navSearch}
          />

          <Notification
            data={navNotification}
            notificationPopLeft={notificationPopLeft}
            setNotificationPopLeft={setNotificationPopLeft}
          />

          <User
            data={metafields.navUser}
            userPopLeft={userPopLeft}
            preRender={preRender}
            onRegistrations={handleRegistrations}
            registrationsSettings={registrationsSettings}
          />
        </div>
      </motion.div>

      <AnimatePresence>
        {showMobileSearchBoard && (
          <MobileSearchBoard
            data={metafields.navSearch}
            onClose={() => setShowMobileSearchBoard(false)}
          />
        )}
      </AnimatePresence>

      {/* 手机端 展开后 Box */}
      <UnfoldWingsMobile
        lang={lang}
        rootDark={rootDark}
        shopCommon={shopCommon}
        pageType={pageType}
        categories={categories}
        mobileShowDetail={mobileShowDetail}
        mobileMenuOpen={mobileMenuOpen}
        dropDownContent={dropDownContent}
        dropDownSmallContent={dropDownSmallContent}
        currentDownBoxContent={currentDownBoxContent}
        currentDownBoxContentAll={currentDownBoxContentAll}
        currentSmallBoxContent={currentSmallBoxContent}
        setDropDownSmallBoxContent={setDropDownSmallBoxContent}
        setDropDownBoxContent={setDropDownBoxContent}
        onMobileMenuHidden={onMobileMenuHidden}
        metafields={metafields}
        setUserPopLeft={setUserPopLeft}
        userPopLeft={userPopLeft}
        onRegistrations={handleRegistrations}
        registrationsSettings={registrationsSettings}
      />
    </>
  )
}

export default Header
