import cn from 'classnames'
import {
  ChevronRightIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'
import { motion, AnimatePresence } from 'framer-motion'
import ProductCard from '../CollectionProductCard'
import UlList from '../ulList'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Text } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import Button from '@components/control/Button'
import styles from './index.module.css'
import { easingTransitions } from '@lib/utils/animate'
import BottomBar from '../BottomBar/BottomBar'

const FlexWrapper = ({ showActualHeight, children, device, firstLeval }) => {
  const ch = window?.innerWidth >= 1024 ? 200 : 380
  const [wrapperHeight, setWrapperHeight] = useState(ch)
  const wrapperRef = useRef(null)
  useEffect(() => {
    const scrollHeight = wrapperRef.current.scrollHeight
    if (showActualHeight) {
      setWrapperHeight(scrollHeight > ch ? scrollHeight : ch)
    } else {
      setWrapperHeight(ch)
    }
  }, [wrapperRef.current, showActualHeight])
  return (
    <div
      ref={wrapperRef}
      style={{
        height: firstLeval ? 'auto' : `${wrapperHeight}px`,
      }}
      className={cn(
        'w-full gap-[16px] overflow-hidden transition-all duration-300 ease-in-out l:flex l:!h-[auto] l:flex-nowrap l:gap-[12px] l:overflow-x-auto  min-l:flex min-l:flex-wrap min-l:justify-start',
        `${device ? `wrapper-ref_${device}` : ''}`
      )}
    >
      {children}
    </div>
  )
}

const isFistLevel = ({ collection }) => {
  return (
    collection?.productList?.length > 0 &&
    collection?.collectionList?.length === 0
  )
}

function NewCollections({
  currentDownBoxContent,
  s,
  collectionHover,
  setCollectionHover,
  currentCollectionHover,
  pageType,
  shopCommon,
  lang,
}) {
  console.log('currentCollectionHover', currentCollectionHover)
  const { setRelativeLink } = useRelativeLink()

  const firstLeval = useMemo(() => {
    return (
      currentCollectionHover?.productList?.length > 0 &&
      currentCollectionHover?.collectionList?.length === 0
    )
  }, [currentCollectionHover])

  const has_custom = currentCollectionHover?.has_custom

  const prodInfoCustomHandler = (is_custom, customProductList) => {
    return is_custom && customProductList?.[0]?.custom_title
  }

  const prodInfoCustom = useMemo(() => {
    return prodInfoCustomHandler(
      has_custom,
      currentCollectionHover?.customProductList
    )
  }, [has_custom, currentCollectionHover?.customProductList])

  function viewList() {
    window?.innerWidth >= 1024
      ? currentCollectionHover?.collectionList?.map((item) => false)
      : currentDownBoxContent?.content
          ?.map((collection) => {
            return collection?.collectionList?.map((item) => false)
          })
          ?.flat()
  }

  const [viewMore, setViewMore] = useState(-1)

  useEffect(() => {
    viewList()
    window?.addEventListener('resize', viewList)
    return () => {
      window?.removeEventListener('resize', viewList)
    }
  }, [])

  const viewMoreHandler = (index) => {
    if (viewMore == index) {
      setViewMore(-1)
    } else {
      setViewMore(index)
    }
  }

  const [mobileIndex, setMbIndex] = useState(0)

  const hideShowUlList = useMemo(() => {
    return (
      currentDownBoxContent?.content?.length <= 1 &&
      currentDownBoxContent?.hideShowUlList
    )
  }, [currentDownBoxContent])

  const viewMoreHideHandler = () => {
    if (window?.innerWidth < 1024) return
    const wrapperRefs = document.querySelectorAll('.wrapper-ref_pc')
    wrapperRefs?.forEach((wrapperRef, index) => {
      const scrollHeight = wrapperRef?.scrollHeight
      const h = wrapperRef?.style?.clientHeight || 200
      if (scrollHeight > h) {
        document
          .querySelector(`.view_more_${index}`)
          ?.classList.remove('hidden')
      } else {
        document.querySelector(`.view_more_${index}`)?.classList.add('hidden')
      }
    })
  }

  useEffect(() => {
    setViewMore(-1)
    viewMoreHideHandler()
    window?.addEventListener('resize', viewMoreHideHandler)
    return () => {
      window?.removeEventListener('resize', viewMoreHideHandler)
    }
  }, [currentCollectionHover])

  return (
    <>
      <style jsx global>
        {`
          .custom-scroll {
            overscroll-behavior: contain;
          }
          .custom-scroll::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      {!hideShowUlList && (
        <UlList
          className="l:hidden"
          currentDownBoxContent={currentDownBoxContent}
          collectionHover={collectionHover}
          setCollectionHover={setCollectionHover}
          currentCollectionHover={currentCollectionHover}
          s={s}
          styles={styles}
        ></UlList>
      )}
      <div
        className={cn(
          s.optionContent,
          'custom-scroll !col-span-11 h-full max-h-[700px] min-h-[300px] !overflow-y-auto  pb-[30px] l:hidden xxl:max-h-[550px]',
          `${hideShowUlList ? '!col-span-14' : ''}`
        )}
      >
        {firstLeval ? (
          <FlexWrapper firstLeval={firstLeval}>
            {currentCollectionHover?.productList?.map((product, index) => {
              return (
                <ProductCard
                  has_custom={prodInfoCustom}
                  pageType={pageType}
                  product={product}
                  key={`starProducts-${
                    product?.name || product?.custom_title
                  }-${index}`}
                  shopCommon={shopCommon}
                ></ProductCard>
              )
            })}
          </FlexWrapper>
        ) : (
          <div className="col-span-full flex flex-col gap-[16px]">
            {currentCollectionHover?.collectionList?.map(
              (collection, index) => {
                return (
                  <div className="" key={index + collection?.name}>
                    <div className="flex items-center gap-[16px]">
                      <span
                        href={setRelativeLink({
                          link: has_custom
                            ? collection?.href || collection?.path
                            : collection?.path,
                        })}
                        className="text-[14px] font-semibold text-black"
                        dangerouslySetInnerHTML={{
                          __html: collection?.name,
                        }}
                      ></span>
                      <div
                        onClick={() => {
                          viewMoreHandler(index)
                        }}
                        className={cn(
                          'view_more flex flex-1 cursor-pointer items-center gap-[4px]',
                          `view_more_${index}`
                        )}
                      >
                        <Text
                          html={
                            viewMore == index
                              ? currentDownBoxContent?.viewLess
                              : currentDownBoxContent?.viewMore
                          }
                          className="text-[12px] font-medium text-black"
                        ></Text>
                        <ChevronRightIcon
                          className={cn(
                            'w-[12px] transition-all',
                            `${
                              viewMore == index ? 'rotate-[90deg]' : 'rotate-0'
                            }`
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-[15px]">
                      <FlexWrapper
                        device="pc"
                        index={index}
                        showActualHeight={viewMore == index}
                      >
                        {collection?.productList?.map((product, proIndex) => {
                          return (
                            <ProductCard
                              has_custom={prodInfoCustom}
                              pageType={pageType}
                              product={product}
                              key={`starProducts-child-${product?.name}-${proIndex}`}
                              shopCommon={shopCommon}
                            ></ProductCard>
                          )
                        })}
                      </FlexWrapper>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        )}
        {!currentCollectionHover?.hideBottomBar && (
          <BottomBar
            has_custom={has_custom}
            s={s}
            setRelativeLink={setRelativeLink}
            currentCollectionHover={currentCollectionHover}
            pageType={pageType}
            lang={lang}
          />
        )}
      </div>

      <div className="min-l:hidden">
        <ul className={s.menuMobile}>
          {currentDownBoxContent?.content?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, 'pl-[5px]', {
                  [s.active]: mobileIndex === index,
                })}
              >
                <button
                  onClick={() => {
                    if (mobileIndex >= 0 && mobileIndex === index) {
                      setMbIndex(-1)
                    } else {
                      setMbIndex(index)
                    }
                    window.location.hash = `navi_3_${collection?.name
                      ?.toLowerCase()
                      .replace(/\s+/g, '_')}`
                  }}
                >
                  <span className="text-[13px] font-[500]">
                    {collection?.name}
                  </span>
                  <ChevronRightIcon
                    width={14}
                    className={cn(
                      s.collectionMenuDown,
                      'transition-all',
                      `${
                        mobileIndex === index
                          ? 'rotate-[90deg]'
                          : 'rotate-[0deg]'
                      }`
                    )}
                  />
                </button>

                <motion.div
                  className={s.optionContent}
                  animate={mobileIndex === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  <div className="w-full">
                    {isFistLevel({ collection }) ? (
                      <FlexWrapper>
                        {collection?.productList?.map((product, index) => {
                          return (
                            <ProductCard
                              key={`starProducts-mb-${product?.name}-${index}`}
                              has_custom={prodInfoCustomHandler(
                                collection?.has_custom,
                                collection?.customProductList
                              )}
                              pageType={pageType}
                              product={product}
                              shopCommon={shopCommon}
                            ></ProductCard>
                          )
                        })}
                      </FlexWrapper>
                    ) : (
                      <div className="w-full">
                        {collection?.collectionList?.map(
                          (child, childIndex) => {
                            return (
                              <div
                                className="mt-[20px] w-full"
                                key={childIndex}
                              >
                                {child?.name && (
                                  <Text
                                    className="mb-[14px] text-[14px] font-[600] text-black"
                                    html={child?.name}
                                  ></Text>
                                )}
                                {child?.productList && (
                                  <FlexWrapper
                                    showActualHeight={viewMore == childIndex}
                                  >
                                    {child?.productList?.map(
                                      (product, index) => {
                                        return (
                                          <ProductCard
                                            key={`starProducts-mb-child-${product?.name}-${index}`}
                                            has_custom={prodInfoCustomHandler(
                                              collection?.has_custom,
                                              child?.customProductList
                                            )}
                                            pageType={pageType}
                                            product={product}
                                            shopCommon={shopCommon}
                                          ></ProductCard>
                                        )
                                      }
                                    )}
                                  </FlexWrapper>
                                )}

                                {/* <div
                                  onClick={() => {
                                    viewMoreHandler(childIndex)
                                  }}
                                  className="mt-[18px] flex cursor-pointer items-center gap-[4px]"
                                >
                                  <Text
                                    html={
                                      viewMore == childIndex
                                        ? currentDownBoxContent?.viewLess
                                        : currentDownBoxContent?.viewMore
                                    }
                                    className="text-[12px] font-medium text-black"
                                  ></Text>
                                  <ChevronDownIcon
                                    className={cn(
                                      'w-[12px] transition-all',
                                      `${
                                        viewMore == childIndex
                                          ? 'rotate-[180deg]'
                                          : 'rotate-0'
                                      }`
                                    )}
                                  />
                                </div> */}
                              </div>
                            )
                          }
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mx-auto my-[25px] w-full">
                    {
                      <a
                        href={setRelativeLink({
                          link: collection?.has_custom
                            ? collection?.href || collection?.path
                            : collection?.path,
                        })}
                        className="flex h-[36px] w-[202px] items-center !justify-center   border border-[#00A9E0] bg-white px-[10px] py-[5px] text-[14px] font-[500]"
                      >
                        <span
                          style={{
                            background:
                              'linear-gradient(89deg, #00A9E0 1.39%, #00DB84 99.5%)',
                            backgroundClip: 'text',
                            webkitBackgroundClip: 'text',
                            webkitTextFillColor: 'transparent',
                          }}
                        >
                          {collection?.jumpCollection
                            ? collection.jumpCollection
                            : lang?.seeAll?.replace(
                                '%collection%',
                                collection?.name
                              )}
                        </span>
                      </a>
                    }

                    <div className="mt-[12px] flex w-full items-center gap-[15px] overflow-x-auto">
                      {currentDownBoxContent?.content?.[
                        mobileIndex
                      ]?.custom_others_links?.map((link, index) => {
                        return (
                          <div
                            className="flex flex-shrink-0 items-center justify-center gap-[2px]  last:border-b-0 last:pb-0"
                            key={index}
                          >
                            <motion.a
                              className="!w-fit !text-[14px] font-[500] underline"
                              href={setRelativeLink({
                                link: link?.to,
                              })}
                            >
                              {link?.label}
                              <ChevronRightIcon className={s.linkToArrow} />
                            </motion.a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </motion.div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default NewCollections
